import { useQuery } from '@tanstack/react-query';
import { useCurrentOrganisationDetails } from '../useCurrentOrganisationDetails/useCurrentOrganisationDetails';
import { RuleAnalytics } from '../../domain/ScheduledMessaging';
import { loadRuleAnalytics } from '../../services/ScheduledMessagingApi';
import {
  SCHEDULED_MESSAGING_RULE_ANALYTICS_QUERY_KEY,
  SCHEDULED_MESSAGING_RULE_ANALYTICS_QUERY_STALE_TIME,
} from '../../util/queries';

interface UseRuleAnalyticsResult {
  analytics?: RuleAnalytics;
  loading: boolean;
  error: unknown;
}

export function useRuleAnalytics(ruleId: string | undefined): UseRuleAnalyticsResult {
  const { organisation } = useCurrentOrganisationDetails();

  const { data, isPending, isLoading, error } = useQuery<RuleAnalytics>({
    queryKey: [SCHEDULED_MESSAGING_RULE_ANALYTICS_QUERY_KEY, organisation, ruleId],
    queryFn: async () => loadRuleAnalytics(organisation.organisation, ruleId!),
    staleTime: SCHEDULED_MESSAGING_RULE_ANALYTICS_QUERY_STALE_TIME,
    enabled: !!ruleId && !!organisation,
    retry: false,
  });

  return {
    analytics: data,
    loading: isPending || isLoading,
    error,
  };
}
