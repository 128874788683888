import FormControl from '@mui/material/FormControl';
import React, { useContext } from 'react';
import { LoadingSlider } from '../../../shared/LoadingSlider/LoadingSlider';
import userContext from '../../../../context/UserContext';
import { styled } from '@mui/system';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller, useFormContext } from 'react-hook-form';

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,

  '& .slider-label': {
    minWidth: 320,
    color: theme.palette.text.secondary,
  },
}));

function range(start: number, stop: number, step = 1): number[] {
  return Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step);
}

export function MaxPatientsSlider() {
  function makeMarks(max: number, step = Math.max(max, 1)) {
    return range(0, max + 1, step).map((value) => ({
      value,
      label: value,
    }));
  }

  const { control } = useFormContext();

  const context = useContext(userContext);

  const user = context.user;
  const isSuperUser = user?.superUser;
  const isSupportUser = user?.supportUser;
  const canEditMaxCap = isSuperUser || isSupportUser;

  return (
    <Controller
      name="maxPatients"
      control={control}
      render={({
        field: {
          onChange,
          value: [maxDefinedByPractice, maxAllowedByAbtrace],
        },
      }) => (
        <FormControl sx={{ width: '570px' }}>
          <StyledFormControlLabel
            control={
              canEditMaxCap ? (
                <LoadingSlider
                  loading={false}
                  min={0}
                  max={2000}
                  step={20}
                  marks={makeMarks(2000, 500)}
                  value={[maxDefinedByPractice, maxAllowedByAbtrace]}
                  disableSwap
                  valueLabelDisplay="auto"
                  getAriaLabel={(index: number) =>
                    index === 0
                      ? 'Maximum number of patients to contact'
                      : 'Maximum allowed number of patients to contact (Abtrace defined)'
                  }
                  valueLabelFormat={(currentValue, index) =>
                    index === 0 ? `Practice limit: ${currentValue}` : `Abtrace limit: ${currentValue}`
                  }
                  track={false}
                  onChange={(_ev, values) => onChange(values as number[])}
                  onChangeCommitted={(_ev, values) => onChange(values as number[])}
                />
              ) : (
                <LoadingSlider
                  loading={false}
                  min={0}
                  max={maxAllowedByAbtrace}
                  step={20}
                  marks={makeMarks(maxAllowedByAbtrace)}
                  value={maxDefinedByPractice}
                  valueLabelDisplay="auto"
                  aria-label="Maximum number of patients to contact"
                  onChange={(_ev, value) => onChange([value as number, maxAllowedByAbtrace])}
                  onChangeCommitted={(_ev, value) => onChange([value as number, maxAllowedByAbtrace])}
                />
              )
            }
            label="Maximum number of patients to contact"
            labelPlacement="start"
            classes={{
              label: 'slider-label',
            }}
          />
        </FormControl>
      )}
    />
  );
}
