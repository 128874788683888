import { RuleAnalytics } from '../domain/ScheduledMessaging';

interface CategorisedRuleAnalytics {
  notYet: {
    total: number;
    patients: string[] | undefined;
  };
  ongoing: {
    total: number;
    patients: string[] | undefined;
    perRound: Record<number, number>;
  };
  success: {
    total: number;
    patients: string[] | undefined;
    perRound: Record<number, number>;
  };
  failure: {
    total: number;
    patients: string[] | undefined;
    missingContactDetails: number;
    dissented: number;
    maxedOutMessagingRounds: number;
  };
  excluded: {
    total: number;
    patients: string[] | undefined;
    excludedByCriteria: number;
    tooOverdue: number;
  };
}

export function categoriseAnalytics(analytics: RuleAnalytics): CategorisedRuleAnalytics {
  const { totals, breakdown } = analytics;

  return {
    notYet: {
      total: totals.outsideRecall[0],
      patients: breakdown?.outsideRecall[0],
    },
    ongoing: {
      total: Object.values(totals.undergoingRecall).reduce((a, b) => a + b, 0),
      patients: breakdown ? Object.values(breakdown.undergoingRecall).flat() : undefined,
      perRound: totals.undergoingRecall,
    },
    success: {
      total: Object.entries(totals.outsideRecall)
        .filter(([round]) => parseInt(round) > 0)
        .map(([, count]) => count)
        .reduce((a, b) => a + b, 0),
      patients: breakdown
        ? Object.entries(breakdown.outsideRecall)
            .filter(([round]) => parseInt(round) > 0)
            .map(([, patients]) => patients)
            .flat()
        : undefined,
      perRound: Object.fromEntries(Object.entries(totals.outsideRecall).filter(([round]) => parseInt(round) > 0)),
    },
    failure: {
      total: totals.missingContactDetails + totals.dissented + totals.maxedOutMessagingRounds,
      patients: breakdown
        ? breakdown.missingContactDetails.concat(breakdown.dissented).concat(breakdown.maxedOutMessagingRounds)
        : undefined,
      missingContactDetails: totals.missingContactDetails,
      dissented: totals.dissented,
      maxedOutMessagingRounds: totals.maxedOutMessagingRounds,
    },
    excluded: {
      total: totals.excluded + totals.tooOverdue,
      patients: breakdown ? breakdown.excluded.concat(breakdown.tooOverdue) : undefined,
      excludedByCriteria: totals.excluded,
      tooOverdue: totals.tooOverdue,
    },
  };
}
