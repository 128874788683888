import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import React from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import TextField from '../../../shared/TextField/TextField';
import { buildEmailSignature } from '../../../../util/emails';
import {
  APPOINTMENT_LINK_SECTION_EMAIL,
  APPOINTMENT_LINK_PLACEHOLDER,
  APPOINTMENT_INVITATION_CODE_SECTION_EMAIL,
  APPOINTMENT_INVITATION_CODE_PLACEHOLDER,
} from '../../../../domain/ScheduledMessaging';
import { useCurrentOrganisationDetails } from '../../../../hooks/useCurrentOrganisationDetails/useCurrentOrganisationDetails';

export function EmailContent() {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext();

  const appointmentsAddInvitationLink = watch('appointmentsAddInvitationLink');

  const { organisation } = useCurrentOrganisationDetails();

  return (
    <>
      <Stack gap={2} mt={1}>
        <TextField
          name={'subject'}
          control={control}
          error={errors.subject as FieldError}
          rules={{
            required: 'Subject is required',
          }}
          label="subject"
          required
          variant="standard"
        />

        <Stack gap={0.5}>
          <Typography color="text.secondary" variant="body2" sx={{ userSelect: 'none' }}>
            <em>Dear ⟦Patient Name⟧,</em>
          </Typography>
          <TextField
            name={'content'}
            control={control}
            error={errors.content as FieldError}
            label="content"
            required
            multiline
            rules={{ required: 'Content is required' }}
            variant="standard"
            rows={7}
          />
        </Stack>
        {appointmentsAddInvitationLink && (
          <>
            <Typography color="text.secondary" variant="body2" sx={{ userSelect: 'none' }}>
              <em>{`${APPOINTMENT_LINK_SECTION_EMAIL} ${APPOINTMENT_LINK_PLACEHOLDER}`}</em>
            </Typography>
            <Typography color="text.secondary" variant="body2" sx={{ userSelect: 'none' }}>
              <em>{`${APPOINTMENT_INVITATION_CODE_SECTION_EMAIL} ${APPOINTMENT_INVITATION_CODE_PLACEHOLDER}`}</em>
            </Typography>
          </>
        )}
        <Typography color="text.secondary" variant="body2" display="inline" data-field="email-signature">
          <span
            dangerouslySetInnerHTML={{
              __html: buildEmailSignature(organisation!).replace(/\n/g, '<br />'),
            }}
          />
        </Typography>
      </Stack>
    </>
  );
}
