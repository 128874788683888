import { GET_RULE_ANALYTICS_TIMEOUT, SCHEDULED_MESSAGING_API_NAME } from '../../util/constants';
import {
  Rule,
  RuleAnalytics,
  RuleExecutionReport,
  RuleExecutions,
  RuleOrganisationConfig,
  RuleStats,
  ScheduledMessagingOrganisationConfig,
} from '../../domain/ScheduledMessaging';
import { AxiosInstance } from 'axios';
import { getApiClient, httpDelete, httpGet, httpPost, httpPut, httpPatch, resetApiClient } from '../../util/api';

function client(): AxiosInstance {
  return getApiClient(SCHEDULED_MESSAGING_API_NAME);
}

export async function loadRules(): Promise<Rule[]> {
  return httpGet(client(), '/rules') as Promise<Rule[]>;
}

export async function removeRule(organisation: string, ruleId: string): Promise<ScheduledMessagingOrganisationConfig> {
  return httpDelete(
    client(),
    `/organisations/${organisation}/rules/${ruleId}`,
  ) as Promise<ScheduledMessagingOrganisationConfig>;
}

export async function updateRule(organisation: string, ruleId: string, data: Rule): Promise<Rule> {
  const { metadata, ...rest } = data;

  return httpPut(client(), `/organisations/${organisation}/rules/${ruleId}`, rest) as Promise<Rule>;
}

export async function loadScheduledMessagingConfig(
  organisation: string,
): Promise<ScheduledMessagingOrganisationConfig> {
  return httpGet(client(), `/organisations/${organisation}/config`) as Promise<ScheduledMessagingOrganisationConfig>;
}

export async function updateScheduledMessagingOrganisationConfig(
  organisation: string,
  available?: boolean,
  reportsRecipientUserId?: string | null,
  partnerApiCredentials?: { username: string; password: string },
): Promise<ScheduledMessagingOrganisationConfig> {
  return httpPatch(client(), `/organisations/${organisation}/config`, {
    available,
    reportsRecipientUserId,
    partnerApiCredentials,
  }) as Promise<ScheduledMessagingOrganisationConfig>;
}

export async function updateRuleConfig(
  organisation: string,
  ruleId: string,
  ruleConfig: RuleOrganisationConfig,
): Promise<ScheduledMessagingOrganisationConfig> {
  return httpPut(
    client(),
    `/organisations/${organisation}/rules/${ruleId}/config`,
    ruleConfig,
  ) as Promise<ScheduledMessagingOrganisationConfig>;
}

export async function removeRuleConfig(
  organisation: string,
  ruleId: string,
): Promise<ScheduledMessagingOrganisationConfig> {
  return httpDelete(
    client(),
    `/organisations/${organisation}/rules/${ruleId}/config`,
  ) as Promise<ScheduledMessagingOrganisationConfig>;
}

export async function loadRuleExecutionHistory(organisation: string, ruleId: string): Promise<RuleExecutions> {
  return httpGet(client(), `/organisations/${organisation}/rules/${ruleId}/executions`) as Promise<RuleExecutions>;
}

export async function loadRuleExecution(
  organisation: string,
  ruleId: string,
  executionDate: string,
): Promise<RuleExecutionReport> {
  const response = (await httpGet(
    client(),
    `/organisations/${organisation}/rules/${ruleId}/executions/${executionDate}`,
  )) as { execution: RuleExecutionReport };

  return response.execution;
}

export async function downloadReport(executionId: string): Promise<string> {
  const [, organisation, ruleId, date] = executionId.match(/(.+)#(.+)#(.+)/)!;

  return httpGet(
    client(),
    `/organisations/${organisation}/rules/${ruleId}/executions/${date}/report`,
  ) as Promise<string>;
}

export async function triggerRule(organisation: string, ruleId: string): Promise<unknown> {
  return httpPost(client(), `/organisations/${organisation}/rules/${ruleId}/executions`, {});
}

export async function loadRuleStats(organisation: string, ruleId: string): Promise<RuleStats> {
  return httpGet(client(), `/organisations/${organisation}/rules/${ruleId}/stats`) as Promise<RuleStats>;
}

export async function loadRuleAnalytics(organisation: string, ruleId: string): Promise<RuleAnalytics> {
  return httpGet(client(), `/organisations/${organisation}/rules/${ruleId}/analytics`, {
    config: {
      timeout: GET_RULE_ANALYTICS_TIMEOUT,
      params: {
        includePatients: true,
      },
    },
  }) as Promise<RuleAnalytics>;
}

export async function checkPartnerApiCredentials(
  organisation: string,
  username: string,
  password: string,
): Promise<void> {
  return httpPost(client(), `/organisations/${organisation}/partner-api/check`, {
    username,
    password,
  }) as Promise<void>;
}

export function resetClient() {
  resetApiClient(SCHEDULED_MESSAGING_API_NAME);
}
