declare var CONFIG: string;

export interface Config {
  amplify: {
    region: string;
    auth: {
      userPoolId: string;
      audience: string;
    };
    endpoints: {
      website: string;
      auth: string;
      app: string;
      qofopt: string;
      messaging: string;
      scheduledMessaging: string;
      appointmentBooking: string;
      patientFacing: string;
    };
  };
  datadog: DatadogConfig;
  appointments: {
    appointmentBookingUrlFormat: string;
  };
  patientFacingConfigurations: {
    patientWebsiteContactPreviewPageUrl: string;
  };
  features?: {
    doubleBookingConfiguration?: boolean;
  };
}

interface DatadogConfig {
  service: string;
  tags: Record<string, string>;
  site: string;
  clientToken: string;
}

export default JSON.parse(CONFIG) as Config;
