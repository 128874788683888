import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { ReactNode, useEffect, useState } from 'react';
import { UseDialogConnector } from '../../../hooks/useDialog/useDialog';
import { LoadingActionButton } from '../LoadingActionButton';

export interface ConfirmationDialogConfig {
  title: string;
  text?: string;
  content?: ReactNode;
}

export function ConfirmationDialog({ connector }: { connector: UseDialogConnector<ConfirmationDialogConfig> }) {
  const [open, setOpen] = useState(connector.config !== null);

  useEffect(() => {
    if (connector.config) {
      setOpen(true);
    }
  }, [connector.config]);

  const close = () => {
    setOpen(false);
  };

  if (!connector.config) {
    return <></>;
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        TransitionProps={{
          onExited: () => {
            // Called only when the dialog disappears completely
            connector.close();
          },
        }}
      >
        <DialogTitle>{connector.config.title}</DialogTitle>
        <DialogContent>
          {connector.config.content ? (
            connector.config.content
          ) : (
            <Stack spacing={2}>
              <Typography variant="body1">{connector.config.text}</Typography>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={1}>
            <LoadingActionButton variant="outlined" onClick={close}>
              No
            </LoadingActionButton>
            <Box sx={{ position: 'relative' }}>
              <LoadingActionButton
                onClick={async () => {
                  await connector
                    .submit()
                    .then(close)
                    .catch(() => {});
                }}
                variant="contained"
                disabled={!open}
                data-testid="confirm"
              >
                Yes
              </LoadingActionButton>
            </Box>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
