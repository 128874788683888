import { AxiosInstance } from 'axios';
import { FrontendDefinitions, OrganisationDetails, OrganisationEmail } from '../../domain/Organisation';
import { PatientTaskCategory } from '../../domain/PatientFacing';
import { getApiClient, httpDelete, httpGet, httpPatch, httpPost, httpPut, resetApiClient } from '../../util/api';
import { APP_API_NAME } from '../../util/constants';
import {
  GetCliniciansResponse,
  parseGetCliniciansResponse,
  parseGetFrontendDefinitionsResponse,
  parseGetOrganisationDetailsResponse,
  parseGetOrganisationEmailsResponse,
  parseGetPatientTaskCategoriesResponse,
  parseRegisterOrResendOrganisationEmailResponse,
  parseSearchOrganisationResponse,
} from './model/response';

export type Ehr = 'emis' | 'tpp';

function client(): AxiosInstance {
  return getApiClient(APP_API_NAME);
}

export async function forceDeleteOrganisationEmail(organisation: string, email: string): Promise<void> {
  await httpPut(client(), `/organisations/${organisation}/emails/delete?force=true`, { email });
}

export async function loadOrganisationDetails(
  organisations: string[],
): Promise<Record<string, OrganisationDetails | null>> {
  const data = await httpGet(client(), '/organisations', {
    config: {
      params: {
        ids: organisations.join(','),
      },
    },
  });

  return parseGetOrganisationDetailsResponse(data);
}

export async function loadOrganisationEmails(organisation: string): Promise<OrganisationEmail[]> {
  const data = await httpGet(client(), `/organisations/${organisation}/emails`);
  return parseGetOrganisationEmailsResponse(data).emailAddresses;
}

export async function loadFrontendDefinitions(organisation: string): Promise<FrontendDefinitions> {
  const data = await httpGet(client(), `/frontenddefinitions/${organisation}`);
  return parseGetFrontendDefinitionsResponse(data);
}

export async function loadClinicians(organisation: string, ehr: string): Promise<GetCliniciansResponse> {
  const data = await httpGet(client(), `/clinicians/${ehr}/${organisation}`);
  return parseGetCliniciansResponse(data);
}

export async function loadPatientTaskCategoriesList(organisation: string, ehr: string): Promise<PatientTaskCategory[]> {
  // nullOnError is false because if there is some sort of error, it's an actual error
  // (not like 404 because the organisation is not yet set)
  const data = await httpGet(client(), `/patient-tasks/${ehr}/${organisation}/categories`);
  return parseGetPatientTaskCategoriesResponse(data);
}

export async function registerOrResendOrganisationEmail(
  organisation: string,
  email: string,
): Promise<OrganisationEmail> {
  const data = await httpPost(client(), `/organisations/${organisation}/emails`, { email });
  return parseRegisterOrResendOrganisationEmailResponse(data);
}

export async function updateContractExpiryDate(organisationId: string, contractExpiryDate: string): Promise<void> {
  await httpPatch(client(), `/organisations/${organisationId}`, {
    contract_expiry_date: contractExpiryDate,
  });
}

export async function updateEhr(organisationId: string, ehr: Ehr): Promise<void> {
  await httpPatch(client(), `/organisations/${organisationId}`, {
    ehr: ehr,
  });
}

export async function updateOnSiteEncryption(organisationId: string, onSiteEncryption: boolean): Promise<void> {
  await httpPatch(client(), `/organisations/${organisationId}`, {
    on_site_encryption: onSiteEncryption,
  });
}

export async function updatedAdvancedFiltering(organisationId: string, advancedFiltering: boolean) {
  await httpPatch(client(), `/organisations/${organisationId}`, {
    advanced_filtering: advancedFiltering,
  });
}

export async function searchOrganisation(filterString: string): Promise<Record<string, OrganisationDetails>> {
  const data = await httpGet(client(), '/organisations', {
    config: {
      params: {
        filterstring: filterString,
      },
    },
  });

  return parseSearchOrganisationResponse(data);
}

export function resetClient() {
  resetApiClient(APP_API_NAME);
}

export async function verifyOrganisationEmail(organisation: string, code: string): Promise<void> {
  await httpDelete(client(), `/organisations/${organisation}/codes/${code}`);
}
