import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Container from '@mui/system/Container';
import Stack from '@mui/system/Stack';
import React, { useContext, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import ConfigContext from '../../../../context/ConfigContext';
import {
  PatientTaskCategory,
  PatientTaskConfigurationItem,
  PatientTasksConfiguration,
  PatientTaskStatusFieldNamesEnum,
} from '../../../../domain/PatientFacing';
import { enqueueErrorNotification, enqueueInfoNotification } from '../../../../redux/reducers/notificationsReducer';
import * as AppApi from '../../../../services/AppApi';
import * as PatientFacingConfigApi from '../../../../services/PatientFacingConfigApi';
import { EMAIL_REGEX } from '../../../../util/constants';
import { LoadingSection } from '../../../shared/LoadingSection/LoadingSection';
import { NoDataToDisplay } from '../../../shared/NoDataToDisplay/NoDataToDisplay';

interface Props {
  organisation: string;
  ehr: string;
  enabled: boolean;
}

const drawerWidth = 250;

const TASK_CATEGORY_FIELD_CONFIGS = [
  { name: 'Overdue', status: 'overdue', color: '#E57373' },
  { name: 'Up to date', status: 'upToDate', color: '#81C784' },
  { name: 'Due by: [[date]]', status: 'dueBy', color: '#FFB74D' },
];

const generateDynamicFormFieldData = (
  taskCategories: PatientTaskCategory[],
  existingTaskConfigurations: PatientTaskConfigurationItem[],
): Record<string, string | boolean> => {
  const getFormValue = (
    taskCode: string,
    fieldName: PatientTaskStatusFieldNamesEnum,
    defaultValue: string | boolean,
  ): string | boolean => existingTaskConfigurations.find((task) => task.code === taskCode)?.[fieldName] ?? defaultValue;

  // Dynamically generate the form fields from the existing categories in the
  // format: `dynamic__CategoryCode__TaskStatus`
  // NOTE: We display all task categories (whether there are saved settings for them or not)
  // and ONLY the task categories (meaning we ignore settings for tasks the don't exist). This
  // is because if a task category is removed from the query definitions, we don't want it to
  // display anymore since it means it does not exist now.
  return taskCategories.reduce((acc, curr) => {
    return Object.assign(acc, {
      [`dynamic__${curr.code}__overdue`]: getFormValue(curr.code, PatientTaskStatusFieldNamesEnum.overdue, ''),
      [`dynamic__${curr.code}__upToDate`]: getFormValue(curr.code, PatientTaskStatusFieldNamesEnum.upToDate, ''),
      [`dynamic__${curr.code}__dueBy`]: getFormValue(curr.code, PatientTaskStatusFieldNamesEnum.dueBy, ''),
      [`dynamic__${curr.code}__active`]: getFormValue(curr.code, PatientTaskStatusFieldNamesEnum.active, true),
    });
  }, {});
};

const formatPatientTasksFormValues = (
  rawFormValues: Record<string, any>,
  taskCategories: PatientTaskCategory[],
): PatientTasksConfiguration => {
  const { active, taskInputsActive, taskInputsDeliveryEmailAddress, ...dynamicFields } = rawFormValues;

  const tasks = taskCategories.map((category) => ({
    code: category.code,
    active: dynamicFields[`dynamic__${category.code}__active`],
    overdueStatusDescription: dynamicFields[`dynamic__${category.code}__overdue`],
    upToDateStatusDescription: dynamicFields[`dynamic__${category.code}__upToDate`],
    dueByStatusDescription: dynamicFields[`dynamic__${category.code}__dueBy`],
  }));

  const taskInputs = {
    active: taskInputsActive,
    deliveryEmailAddress: taskInputsDeliveryEmailAddress,
  };

  return { active, tasks, taskInputs };
};

const convertErrorPath = (path: string): string => {
  const errorPathsMatch = {
    'taskInputs.deliveryEmailAddress': 'taskInputsDeliveryEmailAddress',
  };

  // If there are no custom field matches, use the actual field name
  return errorPathsMatch[path] ?? path;
};

export function PatientTasks({ organisation, ehr, enabled }: Props) {
  // Feature toggle
  const displayQuestionnaires = useContext(ConfigContext).features?.displayQuestionnaires === true;

  const dispatch = useDispatch();
  const [errorLoadingData, setErrorLoadingData] = useState<boolean>(false);
  const [taskCategories, setTaskCategories] = useState<PatientTaskCategory[]>([]);
  const [tasksDefaultDescriptions, setTasksDefaultDescriptions] = useState<Record<string, string>>({});
  const [selectedTaskCategory, setSelectedTaskCategory] = useState('');
  const {
    handleSubmit,
    formState: { isLoading, isSubmitting },
    control,
    resetField,
    getFieldState,
    getValues,
    setError,
  } = useForm({
    defaultValues: async (): Promise<Record<string, string | boolean> | undefined> => {
      try {
        /* **************
         * NOTES:
         *  * `taskCategoriesResult` cannot be null, any error on the API will throw an error which will be
         *     caught in the catch below
         *  * `existingConfiguration` CAN be null, meaning the organisation does not yet have configurations set
         *  * `tasksDefaultDescriptions` cannot be null, any error on the API will throw an error which will be
         *     caught in the catch below
         *
         * This discrepancy is due to the fact that if the taskCategoriesResult request fails, we CANNOT
         * continue rendering the form (since we don't have the categories list). For existingConfiguration
         * we only abort rendering the form if there's an actual API error and not just a 404 (which returns
         * existingConfiguration as null). This means that the organisation in question has no data yet, so
         * we render an empty form!
         */
        const [taskCategoriesResult, existingConfiguration, tasksDefaultDescriptionsResult] = await Promise.all([
          AppApi.loadPatientTaskCategoriesList(organisation, ehr),
          PatientFacingConfigApi.loadPatientTasksConfig(organisation),
          PatientFacingConfigApi.loadPatientTasksDefaultDescriptions(),
        ]);

        // Task categories should not be empty for an organisation, but if they ever do,
        // it can be due to: queries haven't run OR latest run is very old
        if (!taskCategoriesResult.data.length) {
          throw new Error('No available task categories');
        }

        setTaskCategories(taskCategoriesResult.data);
        setTasksDefaultDescriptions(tasksDefaultDescriptionsResult);
        setSelectedTaskCategory(taskCategoriesResult.data[0].code);

        return {
          active: existingConfiguration?.active ?? true,
          taskInputsActive: existingConfiguration?.taskInputs.active ?? false,
          taskInputsDeliveryEmailAddress: existingConfiguration?.taskInputs.deliveryEmailAddress ?? '',
          ...generateDynamicFormFieldData(taskCategoriesResult.data, existingConfiguration?.tasks ?? []),
        };
      } catch (error) {
        setErrorLoadingData(true);
        dispatch(
          enqueueErrorNotification(
            `Unable to load patient tasks configurations for organisation ${organisation}`,
            error,
          ),
        );
        return;
      }
    },
  });

  const submitForm = (fieldName: string) => async (formValues: FieldValues) => {
    // If the field was not modified, there is no need to save
    if (!getFieldState(fieldName).isDirty) {
      return;
    }

    try {
      await PatientFacingConfigApi.savePatientTasksConfig(
        organisation,
        formatPatientTasksFormValues(formValues, taskCategories),
      );
      dispatch(enqueueInfoNotification(`Updated patient tasks configurations for organisation ${organisation}`));

      // Reset the field state so that we can evaluate further changes (isDirty)
      resetField(fieldName, { defaultValue: getValues(fieldName) });
    } catch (error: any) {
      dispatch(
        enqueueErrorNotification(`Unable to save patient tasks configurations for organisation ${organisation}`, error),
      );

      // Set the field errors in the form, if they exist
      if (error.cause?.response?.data?.issues?.length) {
        error.cause.response.data.issues.forEach((issue) => {
          setError(convertErrorPath(issue.path), { message: issue.message });
        });
      }
    }
  };

  const globalPatientTasksToggleValue = getValues('active');
  const globalPatientTasksTaskInputsToggleValue = getValues('taskInputsActive');

  return (
    <LoadingSection loading={isLoading}>
      <Backdrop open={isSubmitting} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress data-testid="spinner" />
      </Backdrop>
      {errorLoadingData && <NoDataToDisplay />}
      {!errorLoadingData && (
        <>
          <FormGroup
            sx={{
              maxWidth: '850px',
              '& .toggle-stack': {
                alignItems: 'center',
                marginBottom: '20px',
                '& .title-toggle': {
                  width: 'fit-content',
                  whiteSpace: 'nowrap',
                },
                '& .item-toggle': {
                  width: 'auto',
                  marginLeft: '15px',
                  '& .MuiTypography-subtitle2': {
                    marginLeft: '10px',
                    display: 'inline',
                    fontSize: '18px',
                    fontWeight: '700',
                    verticalAlign: 'middle',
                  },
                },
                '& .title': {
                  width: '150px',
                },
                '& .content': {
                  '& .MuiTextField-root': {
                    width: '100%',
                  },
                },
              },
            }}
          >
            <FormControl fullWidth margin="normal">
              <Stack direction="row" spacing={5} className="toggle-stack">
                <Container disableGutters className="title-toggle">
                  <Typography variant="body1">Patient tasks are:</Typography>
                </Container>

                <Container disableGutters className="item-toggle">
                  <Controller
                    control={control}
                    name="active"
                    render={({ field: { onChange, value, name } }) => (
                      <Switch
                        edge="end"
                        name={name}
                        checked={!!value}
                        onChange={(event, ...args) => {
                          onChange(event, ...args);
                          handleSubmit(submitForm(name))(event);
                        }}
                        disabled={!enabled}
                        data-testid="patient_tasks__active__switch"
                      />
                    )}
                  />
                  <Typography variant="subtitle2">{globalPatientTasksToggleValue ? 'ON' : 'OFF'}</Typography>
                </Container>
              </Stack>

              {displayQuestionnaires && (
                <>
                  <Stack direction="row" spacing={5} className="toggle-stack">
                    <Container disableGutters className="title-toggle">
                      <Typography variant="body1">Questionnaires are:</Typography>
                    </Container>

                    <Container disableGutters className="item-toggle">
                      <Controller
                        control={control}
                        name="taskInputsActive"
                        render={({ field: { onChange, value, name } }) => (
                          <Switch
                            edge="end"
                            name={name}
                            checked={!!value}
                            onChange={(event, ...args) => {
                              onChange(event, ...args);
                              handleSubmit(submitForm(name))(event);
                            }}
                            disabled={!enabled || !globalPatientTasksToggleValue}
                            data-testid="patient_tasks__task_inputs_active__switch"
                          />
                        )}
                      />
                      <Typography variant="subtitle2">
                        {globalPatientTasksTaskInputsToggleValue ? 'ON' : 'OFF'}
                      </Typography>
                    </Container>

                    {/* This should be uncommented when we have the documentation link for this feature
                    <Container disableGutters className="title-toggle">
                      <Typography variant="subtitle1">
                        <Link
                          sx={{ color: currentTheme.palette.nhsPrimary.main, textDecorationColor: 'inherit' }}
                          href=".........."
                          target="_blank"
                          rel="noopener"
                        >
                          Learn about questionnaires
                        </Link>
                      </Typography>
                    </Container>
                    */}
                  </Stack>

                  <Stack direction="row" spacing={5}>
                    <Container disableGutters>
                      <Controller
                        control={control}
                        name="taskInputsDeliveryEmailAddress"
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                          <TextField
                            sx={{ maxWidth: '550px', marginLeft: '50px', marginBottom: '40px' }}
                            label="Questionnaire recipient email"
                            name={name}
                            type="email"
                            fullWidth
                            onChange={onChange}
                            value={value}
                            disabled={
                              !enabled || !globalPatientTasksToggleValue || !globalPatientTasksTaskInputsToggleValue
                            }
                            error={!!error}
                            helperText={error?.message}
                            onBlur={handleSubmit(submitForm(name))}
                            data-testid="patient_tasks__task_inputs_email"
                          />
                        )}
                        rules={{
                          pattern: {
                            value: EMAIL_REGEX,
                            message: 'Invalid email',
                          },
                        }}
                      />
                    </Container>
                  </Stack>
                </>
              )}
            </FormControl>
          </FormGroup>

          <Box
            sx={{
              display: 'flex',
              maxWidth: '850px',
              '&.disabled': {
                '& .MuiTypography-root': {
                  opacity: 0.4,
                },
              },
            }}
            className={globalPatientTasksToggleValue ? '' : 'disabled'}
            data-testid="patient_tasks__task-categories-box"
          >
            <Drawer
              variant="permanent"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': { width: drawerWidth, position: 'relative', boxSizing: 'border-box' },
              }}
            >
              <Toolbar>
                <Typography variant="h6">Task Categories</Typography>
              </Toolbar>

              <Box sx={{ overflow: 'auto' }}>
                <List
                  disablePadding
                  sx={(theme) => ({
                    '& .MuiTypography-root': { fontWeight: 500, fontSize: 18, padding: '10px' },
                    '& .MuiListItemButton-root': {
                      borderRight: '2px solid transparent',
                      '&.Mui-selected': { borderRight: `2px solid ${theme.palette.primary.main}` },
                    },
                  })}
                >
                  {taskCategories.map((element) => (
                    <ListItem key={`${element.code}-list-item`} disablePadding>
                      <ListItemButton
                        onClick={() => setSelectedTaskCategory(element.code)}
                        selected={selectedTaskCategory === element.code}
                      >
                        <ListItemText
                          primary={element.name}
                          data-testid={`patient_tasks__drawer_item__${element.name}`}
                        />

                        <Controller
                          control={control}
                          name={`dynamic__${element.code}__active`}
                          render={({ field: { onChange, value, name } }) => (
                            <Switch
                              edge="end"
                              name={name}
                              checked={!!value}
                              onChange={(event, ...args) => {
                                onChange(event, ...args);
                                handleSubmit(submitForm(name))(event);
                              }}
                              disabled={!enabled || !globalPatientTasksToggleValue}
                              data-testid={`patient_tasks__${element.code}__active__switch`}
                            />
                          )}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Drawer>

            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 3,
                paddingTop: 0,
                paddingRight: 0,
                paddingLeft: '60px',
                '& .hidden': { display: 'none' },
                '& .title': { marginBottom: '20px', marginTop: '16px' },
                '& .task-container': {
                  marginBottom: '60px',
                  '& .task-type': { marginLeft: 0, marginBottom: '10px', width: 'auto', padding: '1px 7px' },
                  '& .content': {
                    '& .MuiTextField-root': { width: '100%' },
                  },
                },
              }}
            >
              {taskCategories.map(
                (element) =>
                  selectedTaskCategory === element.code && (
                    <span key={element.code}>
                      <Typography variant="h6" className="title">
                        {element.name}
                      </Typography>

                      {TASK_CATEGORY_FIELD_CONFIGS.map((fieldConfig) => {
                        const fieldName = `dynamic__${element.code}__${fieldConfig.status}`;
                        const toggleFieldName = `dynamic__${element.code}__active`;
                        const placeholderText =
                          tasksDefaultDescriptions[PatientTaskStatusFieldNamesEnum[fieldConfig.status]];
                        return (
                          <FormControl fullWidth margin="normal" key={fieldConfig.status} className="task-container">
                            <Container disableGutters className="task-type" sx={{ backgroundColor: fieldConfig.color }}>
                              <Typography variant="subtitle2">{fieldConfig.name}</Typography>
                            </Container>

                            <Container disableGutters className="content">
                              <Controller
                                control={control}
                                name={fieldName}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                  <TextField
                                    name={name}
                                    multiline
                                    rows={3}
                                    type="text"
                                    onChange={onChange}
                                    value={value}
                                    placeholder={placeholderText}
                                    disabled={!enabled || !globalPatientTasksToggleValue || !getValues(toggleFieldName)}
                                    error={!!error}
                                    helperText={error?.message}
                                    onBlur={handleSubmit(submitForm(name))}
                                    data-testid={`patient_tasks__${fieldName}`}
                                  />
                                )}
                              />
                            </Container>
                          </FormControl>
                        );
                      })}
                    </span>
                  ),
              )}
            </Box>
          </Box>
        </>
      )}
    </LoadingSection>
  );
}
