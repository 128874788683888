import { z } from 'zod';
import { OrganisationMember, OrganisationMemberWithConfig } from '../../../domain/Organisation';
import { Invitation } from '../../../domain/Invitation';
import { Application } from '../../../domain/Application';
import { coalesce } from '../../../util/schema';
import { DownloadUrls } from '../../../domain/DownloadUrls';
import { optimisationConfigSchema } from '../../qofOpt/model/response';
import { appointmentsConfigSchema } from '../../appointments/model/response';
import { organisationConfigSchema } from '../../scheduledMessaging/model/response';
import { OrgConfig } from '../../../pages/overview/helpers';

const organisationsResponseSchema = z.object({
  organisations: z.array(z.string()),
});

export function parseUserlessOrganisationsResponse(response: unknown): { organisations: string[] } {
  return organisationsResponseSchema.parse(response);
}

export function parseAllOrganisationsResponse(response: unknown): { organisations: string[] } {
  return organisationsResponseSchema.parse(response);
}

const organisationMemberSchema = z.object({
  id: z.string(),
  email: z.string(),
  permissions: z.array(z.enum(['admin', 'optimisation', 'betaTester', 'analytics'])),
});

export function parseOrganisationMemberResponse(response: unknown): OrganisationMember {
  return organisationMemberSchema.parse(response);
}

const organisationMembersSchema = z.array(organisationMemberSchema);

export function parseOrganisationMembersResponse(response: unknown): OrganisationMember[] {
  return organisationMembersSchema.parse(response);
}

const organisationMemberWithConfigSchema = organisationMemberSchema.and(
  z.object({
    configuredAt: z.object({
      appointments: z.boolean(),
      scheduledMessaging: z.object({
        fallback: z.boolean(),
        rules: z.array(
          z.object({
            id: z.string(),
            enabled: z.boolean(),
          }),
        ),
      }),
    }),
  }),
);

export function parseOrganisationMemberWithConfigResponse(response: unknown): OrganisationMemberWithConfig {
  return organisationMemberWithConfigSchema.parse(response);
}

const invitationSchema = z.object({
  email: z.string(),
  organisation: z.string(),
  invitedBy: z.string(),
  expiresAt: z.string(),
});

export function parseInvitationResponse(response: unknown): Invitation {
  return invitationSchema.parse(response);
}

const invitationsSchema = z.array(invitationSchema);

export function parseInvitationsResponse(response: unknown): Invitation[] {
  return invitationsSchema.parse(response);
}

export function parseAcceptInvitationResponse(response: unknown): OrganisationMember {
  return organisationMemberSchema.parse(response);
}

const applicationSchema = z.object({
  id: z.string(),
  email: z.string(),
  organisation: z.string(),
  status: z.enum(['PENDING', 'WITHDRAWN', 'APPROVED', 'DENIED']),
  createdAt: z.string(),
  reviewer: z
    .string()
    .nullish()
    .transform((value) => value ?? null),
  updatedAt: z
    .string()
    .nullish()
    .transform((value) => value ?? null),
});

export function parseApplicationResponse(response: unknown): Application {
  return applicationSchema.parse(response);
}

const applicationsSchema = z.array(applicationSchema);

export function parseOrganisationApplicationsResponse(response: unknown): Application[] {
  return applicationsSchema.parse(response);
}

export function parseUserApplicationsResponse(response: unknown): Application[] {
  return applicationsSchema.parse(response);
}

const downloadUrlsSchema = z.object({
  emis: coalesce(z.string()),
  tpp: coalesce(z.string()),
});

export function parseDownloadUrlsResponse(response: unknown): DownloadUrls {
  return downloadUrlsSchema.parse(response);
}

const messagingConfigSchema = z.object({
  config: z.object({
    singleMessagingEnabled: z.boolean(),
    bulkMessagingEnabled: z.boolean(),
  }),
  stats: z.object({
    EMAIL: z.object({
      sent: z.number().int(),
      limit: z
        .number()
        .int()
        .nullish()
        .transform((limit) => limit ?? null),
    }),
    SMS: z.object({
      sent: z.number().int(),
      limit: z
        .number()
        .int()
        .nullish()
        .transform((limit) => limit ?? null),
    }),
  }),
});

const orgConfigSchema = z.object({
  website: z.object({
    beta_user_count: z.number(),
  }),
  qofopt: optimisationConfigSchema,
  messaging: messagingConfigSchema,
  appointments: appointmentsConfigSchema,
  scheduled: organisationConfigSchema,
});
const orgConfigsSchema = z
  .object({ configs: z.record(z.string(), orgConfigSchema) })
  .transform(({ configs }) => configs);

export function parseOrgConfigsResponse(response: unknown): Record<string, OrgConfig> {
  return orgConfigsSchema.parse(response);
}
