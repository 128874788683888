export const APPOINTMENT_BOOKING_LINK_MORE_INFO_URL = 'https://help.abtrace.co/booking-links';

export type AppointmentsConfig = {
  enabled: boolean;
  organisationEmail: {
    address: string | null;
    verified: boolean;
  } | null;
};

export type SlotType = {
  id: string;
  name: string;
};

export type AppointmentSlotsConfig = {
  holders: { id: string; name: string }[];
  sites: { id: string; name: string }[];
  slotTypes: SlotType[];
};
