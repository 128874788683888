import React, { MouseEvent } from 'react';
import { RuleExecutionSummaryReport } from '../../../../../domain/ScheduledMessaging';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import RuleIcon from '@mui/icons-material/Rule';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import Button from '@mui/material/Button';
import { enqueueErrorNotification } from '../../../../../redux/reducers/notificationsReducer';
import { useDispatch } from 'react-redux';

interface Props {
  downloadCsv: () => Promise<{
    fileName: string;
    content: string;
  }>;
  summary: RuleExecutionSummaryReport;
}

function getEmailSummary(emailsSent: number, emailsDeliveryFailed: number): [string, 'success' | 'warning' | 'error'] {
  const sentPart = `${emailsSent} e-mail${emailsSent === 1 ? '' : 's'} sent`;
  if (emailsDeliveryFailed === 0) {
    return [sentPart, 'success'];
  } else {
    if (emailsDeliveryFailed === emailsSent) {
      return [`${sentPart}, ${emailsDeliveryFailed === 1 ? '' : 'all of '}which failed to deliver`, 'error'];
    } else {
      return [`${sentPart}, ${emailsDeliveryFailed} of which failed to deliver`, 'warning'];
    }
  }
}

function getSmsSummary(
  smsSent: number,
  smsDelivered: number,
  smsDeliveryFailed: number,
): [string, 'success' | 'warning' | 'error'] {
  const sentPart = `${smsSent} SMS message${smsSent === 1 ? '' : 's'} sent`;
  if (smsDelivered === 0 && smsDeliveryFailed === 0) {
    return [sentPart, 'success'];
  } else if (smsDelivered === 0) {
    if (smsDeliveryFailed === smsSent) {
      return [`${sentPart}, ${smsDeliveryFailed === 1 ? '' : 'all of '}which failed to deliver`, 'error'];
    } else {
      return [
        `${sentPart}, ${smsDeliveryFailed} of which failed to deliver (${smsSent - smsDeliveryFailed} pending)`,
        'warning',
      ];
    }
  } else if (smsDeliveryFailed === 0) {
    if (smsDelivered === smsSent) {
      return [`${sentPart}, ${smsDelivered === 1 ? 'which was' : 'all of which were'} delivered`, 'success'];
    } else {
      return [
        `${sentPart}, ${smsDelivered} of which ${smsDelivered === 1 ? 'was' : 'were'} delivered (${smsSent - smsDelivered} pending)`,
        'success',
      ];
    }
  } else if (smsSent === smsDelivered + smsDeliveryFailed) {
    return [
      `${sentPart}, ${smsDelivered} of which ${smsDelivered === 1 ? 'was' : 'were'} delivered and ${smsDeliveryFailed} failed to deliver`,
      'warning',
    ];
  }

  return [
    `${sentPart}, ${smsDelivered} of which ${smsDelivered === 1 ? 'was' : 'were'} delivered and ${smsDeliveryFailed} failed to deliver (${smsSent - smsDelivered - smsDeliveryFailed} pending)`,
    'warning',
  ];
}

function getSelectedSummary(surveyed: number, selected: number): string {
  if (surveyed === selected) {
    return `${selected} patient${selected === 1 ? '' : 's'} selected for messaging`;
  } else if (selected === 0) {
    if (surveyed === 1) {
      return '1 patient considered, but could not be messaged';
    } else {
      return `${surveyed} patients considered, but none could be messaged`;
    }
  } else {
    return `${surveyed} patient${surveyed === 1 ? '' : 's'} considered, of which ${selected} ${selected === 1 ? 'was' : 'were'} selected for messaging`;
  }
}

export function SuccessfulExecutionDetails({ downloadCsv, summary }: Props) {
  const {
    matchedPatients,
    surveyed,
    selected,
    emailed,
    emailsDeliveryFailed,
    texted,
    textsDelivered,
    textsDeliveryFailed,
    unreachable,
    patientRecordUpdateErrors,
    hadPatientsOpenInTpp,
  } = summary;

  const dispatch = useDispatch();

  return (
    <Box m={1}>
      <List subheader={<ListSubheader component="div">Execution report</ListSubheader>}>
        <ListItem>
          <ListItemIcon>
            <RuleIcon />
          </ListItemIcon>
          <ListItemText
            primary={`${matchedPatients} patient${matchedPatients === 1 ? '' : 's'} matched the criteria`}
            slotProps={{
              primary: {
                variant: 'body2',
              },
            }}
          />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <HowToRegIcon />
          </ListItemIcon>
          <ListItemText
            primary={getSelectedSummary(surveyed, selected)}
            slotProps={{
              primary: {
                variant: 'body2',
              },
            }}
          />
        </ListItem>

        {!!emailed &&
          emailed > 0 &&
          (() => {
            const [text, colour] = getEmailSummary(emailed, emailsDeliveryFailed ?? 0);
            return (
              <ListItem>
                <ListItemIcon>
                  <EmailIcon color={colour} />
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  slotProps={{
                    primary: {
                      variant: 'body2',
                    },
                  }}
                />
              </ListItem>
            );
          })()}

        {emailed === 0 && (
          <ListItem>
            <ListItemIcon>
              <EmailIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={'No e-mails were sent'}
              slotProps={{
                primary: {
                  variant: 'body2',
                },
              }}
            />
          </ListItem>
        )}

        {!!texted &&
          texted > 0 &&
          (() => {
            const [text, colour] = getSmsSummary(texted, textsDelivered ?? 0, textsDeliveryFailed ?? 0);
            return (
              <ListItem>
                <ListItemIcon>
                  <SmsIcon color={colour} />
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  slotProps={{
                    primary: {
                      variant: 'body2',
                    },
                  }}
                />
              </ListItem>
            );
          })()}

        {texted === 0 && (
          <ListItem>
            <ListItemIcon>
              <SmsIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={'No SMS messages were sent'}
              slotProps={{
                primary: {
                  variant: 'body2',
                },
              }}
            />
          </ListItem>
        )}

        {!!unreachable && unreachable > 0 && (
          <ListItem>
            <ListItemIcon>
              <WarningIcon color="warning" />
            </ListItemIcon>
            <ListItemText
              primary={`${unreachable} patient${unreachable === 1 ? '' : 's'} not contacted`}
              slotProps={{
                primary: {
                  variant: 'body2',
                },
              }}
            />
          </ListItem>
        )}

        {!!patientRecordUpdateErrors && patientRecordUpdateErrors > 0 && (
          <ListItem>
            <ListItemIcon>
              <ErrorIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={`${patientRecordUpdateErrors} patient record${
                patientRecordUpdateErrors === 1 ? '' : 's'
              } failed to update`}
              slotProps={{
                primary: {
                  variant: 'body2',
                },
              }}
            />
          </ListItem>
        )}

        {hadPatientsOpenInTpp && (
          <ListItem>
            <ListItemIcon>
              <WarningIcon color="warning" />
            </ListItemIcon>
            <ListItemText
              primary="Some patients were open in SystmOne and had to be saved manually for the messages to be recorded"
              slotProps={{
                primary: {
                  variant: 'body2',
                },
              }}
            />
          </ListItem>
        )}
      </List>

      <Button
        href="#"
        onClick={async (event: MouseEvent<HTMLAnchorElement>) => {
          event.preventDefault();
          try {
            const { fileName, content } = await downloadCsv();

            const csvUrl = window.URL.createObjectURL(
              new Blob([content], {
                type: 'text/csv',
              }),
            );

            // Creates a fake <a/> element and clicks on it to make the browser
            // download the report
            const anchor = document.createElement('a');
            anchor.href = csvUrl;
            anchor.download = fileName;
            anchor.click();
          } catch (error) {
            dispatch(enqueueErrorNotification('Unable to download the report', error));
          }
        }}
        size="small"
        sx={{
          marginLeft: 1,
        }}
      >
        Download full report
      </Button>
    </Box>
  );
}
